import React from "react";
import Logo from "../../assets/svgs/logo-brand.svg";
import ColegioImage from "../../assets/images/colegio-inmobiliario.png";
import FacebookIcon from "../../assets/svgs/facebook-gray-icon.svg";
import TwitterIcon from "../../assets/svgs/twitter-gray-icon.svg";
import InstagramIcon from "../../assets/svgs/instagram-gray-icon.svg";
import PhoneIcon from "../../assets/svgs/phone-icon.svg";
import WhatsAppIcon from "../../assets/svgs/whatsapp-icon.svg";
import { getWhatsapp } from "../../functions/contact-functions";


//function current year
const getCurrentYear = () => {
  const currentYear = new Date().getFullYear();
  return currentYear;
}


export default function Footer() {

  return (
    <footer className="footer">
      <div className="section-width p-1r">
        <div className="grid-footer">
          <div className="address text-center">
            <img className="mb-5" src={Logo} alt="Logo de Esteban E. Pérez" />
            <div className="data-address-footer d-flex flex-column">
              <h2>Datos</h2>
              <span className="text-white-1">Av. Crámer 3641 CABA</span>
              <span className="text-white-1">Buenos Aires, Argentina</span>
              <a
                href="mailto: inmobiliariapenindiaz@gmail.com"
                className="text-white-1 fst-italic hover-red"
                target="_blank"
                rel="noreferrer"
              >
                inmobiliariapenindiaz@gmail.com
              </a>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div id="contact" className="contactus d-flex flex-column gap-3">
              <h2>Contactanos</h2>
              <div className="d-flex gap-4">
                <img src={PhoneIcon} alt="icono de teléfono" width="25px" />
                <div className="d-flex flex-column">
                  <a href="tel:011 4 542 0022" target="_blank" rel="noreferrer">
                    011 4 542 0022
                  </a>
                  <a href="tel:011 4 519 0488" target="_blank" rel="noreferrer">
                    011 4 519 0488
                  </a>
                </div>
              </div>
              <div className="d-flex gap-4">
                <img src={WhatsAppIcon} alt="ícono de whatsapp" width="25px" />
                <a
                  href={getWhatsapp()}
                  target="_blank"
                  rel="noreferrer"
                >
                  15 6 591 9185
                </a>
              </div>
            </div>
          </div>
          <div className="followus d-flex flex-column gap-3 ">
            <h2>Seguinos en</h2>
            <div className="d-flex gap-4">
              <a
                href="https://www.instagram.com/penindiazpropiedades/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={InstagramIcon}
                  alt="ícono de instagram"
                  width="25px"
                />
              </a>
              <a
                href="http://facebook.com/penin.diazpropiedades"
                target="_blank"
                rel="noreferrer"
              >
                <img src={FacebookIcon} alt="ícono de facebook" height="25px" />
              </a>
              <a
                href="https://twitter.com/penindiazprop"
                target="_blank"
                rel="noreferrer"
              >
                <img src={TwitterIcon} alt="ícono de twitter" height="25px" />
              </a>
            </div>
          </div>
          <div className="d-flex flex-column gap-4 align-items-center">
            <img
              src={ColegioImage}
              alt="colegio profesional inmobiliario"
              width="145px"
            />
            <div className="d-flex flex-column align-items-centesr ">
              <span className="text-center">CUCICBA </span>
              <span className="text-center">Esteban E. Pérez. </span>
              <span className="text-center"> Matrícula N° 6820</span>
            </div>
          </div>
        </div>
        <div className="footer-signature">
          <p className="copyright text-white-1">
            © Copyright {getCurrentYear()}. Esteban E. Pérez - Penín Díaz Propiedades.
          </p>

          <p className="mediahaus-sign text-white-1 text-center media-core">
            Promoted by <span className="media-core">
            <a
              href="https://www.mediahaus.com.ar"
              target="_blank"
              className="media-core"
              rel="noreferrer"
            >
              {" "}
              MediaHaus
            </a>{" "}
            </span> &amp; powered by MediaCore® {" "}
            {getCurrentYear()}. Todos los derechos reservados.
          </p>
        </div>
      </div>
    </footer>
  );
}
