import React, { useState } from "react";
import Logo from "../../assets/svgs/logo-brand.svg";
import InstagramIcon from "../../assets/svgs/instagram-icon.svg";
import FacebookIcon from "../../assets/svgs/facebook-icon.svg";
import TwitterIcon from "../../assets/svgs/twitter-icon.svg";
import SVG from "../Globals/SVG";

import { useAsunto } from "./../../Contexts/Asunto";

const Header = () => {
  const { dispatch, state } = useAsunto();
  const [menu, setMenu] = useState(false);

  function setAsunto(asunto) {
    dispatch({ type: "setAsunto", payload: asunto });
    setMenu(false);
  }

  return (
    <header id="header" className="header section-width">
      <img className="brand-logo" src={Logo} alt="Esteban E. Pérez logo" />

      <div className="ml-auto d-flex justify-content-center align-items-center gap-3">
        <div
          className={`menu-links-wrapper ${
            menu ? "open-menu-transition" : "close-menu-transition"
          }`}
        >
          <ul>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.zonaprop.com.ar/inmobiliarias/esteban-e.-perez-penin-diaz-propiedades_17014163-inmuebles-orden-publicado-descendente.html"
              >
                PROPIEDADES <span>01</span>
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  setAsunto("Tasación");
                }}
                href="#contacto"
              >
                TASACIONES <span>02</span>
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  setAsunto("Contacto web");
                }}
                href="#contacto"
              >
                CONTACTO <span>03</span>
              </a>
            </li>
          </ul>
        </div>
        <div className="d-flex gap-3 ms-3">
          <a
            href="https://www.instagram.com/penindiazpropiedades/"
            target="_blank"
            rel="noreferrer"
          >
            <SVG svg={InstagramIcon} alt="ícono de instagram" />
          </a>
          <a
            href="http://facebook.com/penin.diazpropiedades"
            target="_blank"
            rel="noreferrer"
          >
            <SVG svg={FacebookIcon} alt="ícono de facebook" />
          </a>
          <a
            href="https://twitter.com/penindiazprop"
            target="_blank"
            rel="noreferrer"
          >
            <SVG svg={TwitterIcon} alt="ícono de twitter" />
          </a>
          <a
            href="tel:011 4 542 0022"
            className="phone-number-header text-white-1 hover-red"
            target="_blank"
            rel="noreferrer"
          >
            4542-0022
          </a>
        </div>

        <div
          className={`${menu ? "menu-btn menu-btn-clicked" : "menu-btn"}`}
          onClick={() => {
            setMenu((m) => !m);
          }}
        >
          <div></div>
        </div>
      </div>
    </header>
  );
};

export default Header;
